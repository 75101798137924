import { template as template_de514dc48f6a48ce854f91828d25de71 } from "@ember/template-compiler";
const FKText = template_de514dc48f6a48ce854f91828d25de71(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
