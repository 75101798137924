import { template as template_284c3852873d4ac893c2c32b15e7cf57 } from "@ember/template-compiler";
const WelcomeHeader = template_284c3852873d4ac893c2c32b15e7cf57(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
